import React from "react";
import EventItemFooter from "components/Schedules/EventItemFooter";
import EventItemParticipation from "components/Schedules/EventItemParticipation";

import { endOfDay, format, isBefore } from "date-fns";

export default function EventItem(
  {
    card_links,
    classroom_icons,
    event,
    is_management,
    person_avatars,
  },
): JSX.Element {
  const today = new Date();

  return (
    <div className="event-list__item">
      <div
        className={isBefore(new Date(event.ends_at), today)
          ? "event-card --past"
          : "event-card"}
      >
        <a
          href={card_links[event.id]}
          className="event-card__link"
        >
          <div className="event-card__header">
            <div className="event-card__time-section">
              {event.recurrence_event_id && (
                <div className="event-card__time-icon">
                  <img className="icon" src="/images/repeat.svg" />
                </div>
              )}
              <div className="event-card__time">
                <time
                  dateTime={format(
                    new Date(event.starts_at),
                    "yyyy-MM-dd HH:mm",
                  )}
                >
                  {format(new Date(event.starts_at), "HH:mm")}
                </time>
                <time
                  dateTime={format(
                    new Date(event.ends_at),
                    "yyyy-MM-dd HH:mm",
                  )}
                >
                  {event.ends_at >
                      endOfDay(new Date(event.starts_at))
                    ? format(
                      new Date(event.ends_at),
                      "M月D日（ddd）",
                    ) +
                      "&nbsp;"
                    : ""}
                  {format(new Date(event.ends_at), "HH:mm")}
                </time>
              </div>
            </div>

            <div className="event-card__participation">
              {(event.publish_scope === "classroom")
                ? (
                  <div className="event-card__publicationrange-label">
                    <span class="icon" data-icon="circle"></span>
                    教室内公開
                  </div>
                )
                : null}
              {is_management ? <EventItemParticipation event={event} /> : null}
            </div>
          </div>
          <div className="event-card__body">
            <div
              className="event-card__avatar"
              dangerouslySetInnerHTML={{
                __html: classroom_icons[event.classroom_id],
              }}
            />

            <div className="event-card__title">
              {event.name}
            </div>
          </div>
          {is_management ? null : (
            <EventItemFooter
              event={event}
              person_avatars={person_avatars}
            />
          )}
        </a>
      </div>
    </div>
  );
}
