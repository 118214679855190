import { format } from "date-fns";

document.addEventListener("turbolinks:load", () => {
  const createButton = document.querySelector(
    ".js-bulk-events-dialog",
  ) as HTMLElement;
  const startDateField = document.getElementById(
    "js-event-start-date",
  ) as HTMLInputElement;
  const bulkCreateConfirmDialogDescription = document.getElementById(
    "bulk-create-confirm-dialog-description",
  ) as HTMLElement;

  if (
    !(createButton && startDateField && bulkCreateConfirmDialogDescription)
  ) {
    return;
  }

  createButton.addEventListener("click", () => {
    const { finalStartDateFormatted, count } = calculateFinalStartDateAndCount(
      startDateField.value,
    );
    bulkCreateConfirmDialogDescription.innerHTML = `${
      escapeHTML(finalStartDateFormatted)
    } まで${
      escapeHTML(count.toString())
    }個の予定が一括で作成されます。<br><span class="page__data-note">※受講者に通知が大量に届くことはありません</span>`;
  });
});

function calculateFinalStartDateAndCount(
  startDateStr: string,
): { finalStartDateFormatted: string; count: number } {
  const startDate = new Date(startDateStr);
  const bulkInsertEndDate = new Date(startDate);
  bulkInsertEndDate.setFullYear(startDate.getFullYear() + 1);

  const calculateStartDate = new Date(startDate);
  let count = 0;

  while (calculateStartDate <= bulkInsertEndDate) {
    count++;
    calculateStartDate.setDate(calculateStartDate.getDate() + 7);
  }

  const finalStartDate = new Date(calculateStartDate);
  finalStartDate.setDate(finalStartDate.getDate() - 7);

  const finalStartDateFormatted = format(finalStartDate, "yyyy/MM/dd");

  return { finalStartDateFormatted, count };
}

function escapeHTML(str: string): string {
  return str.replace(/[&<>"']/g, (match) => {
    const escapeMap: { [key: string]: string } = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#39;",
    };
    return escapeMap[match];
  });
}
