document.addEventListener("turbolinks:load", () => {
  const form = document.getElementById("js-bulk-events-delete-form");

  if (!(form && form instanceof HTMLFormElement)) {
    return;
  }

  form.addEventListener("submit", (event) => {
    event.preventDefault();

    const deleteOptionElement = form.querySelector(
      'input[name="delete_option"]:checked',
    );

    if (
      !(deleteOptionElement && deleteOptionElement instanceof HTMLInputElement)
    ) {
      return;
    }

    if (deleteOptionElement.value === "only") {
      form.action = form.dataset.onlyPath;
    } else if (deleteOptionElement.value === "after") {
      form.action = form.dataset.afterPath;
    } else if (deleteOptionElement.value === "all") {
      form.action = form.dataset.allPath;
    }

    form.submit();
  });
});
