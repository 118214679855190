document.addEventListener("turbolinks:load", () => {
  const div = document.getElementById("js-bulk-events-edit-form");

  if (!(div && div instanceof HTMLDivElement)) {
    return;
  }

  const bulkEventsEditButton = div.querySelector(
    '[data-action="bulk_events-edit-button"]',
  );
  const afterStartsAtRadioButton = div.querySelector(
    'input[name="event[edit_option]"][value="after_starts_at"]',
  );
  const afterStartsAtRadioButtonLabel = div.querySelector(
    'label[for="event_edit_option_after_starts_at"]',
  );
  const cancelButton = div.querySelector(".js-dialog-v2-toggle-switch");

  if (
    !(bulkEventsEditButton &&
      bulkEventsEditButton instanceof HTMLButtonElement &&
      afterStartsAtRadioButton &&
      afterStartsAtRadioButton instanceof HTMLInputElement &&
      afterStartsAtRadioButtonLabel &&
      afterStartsAtRadioButtonLabel instanceof HTMLLabelElement &&
      cancelButton && cancelButton instanceof HTMLButtonElement)
  ) {
    return;
  }

  if (
    afterStartsAtRadioButton &&
    afterStartsAtRadioButton instanceof HTMLInputElement
  ) {
    const deadlineForReplyDate = document.querySelector(
      ".js-deadline-for-reply-date",
    );
    const deadlineForReplyTime = document.querySelector(
      ".js-deadline-for-reply-time",
    );

    if (
      !(deadlineForReplyDate &&
        deadlineForReplyDate instanceof HTMLInputElement &&
        deadlineForReplyTime &&
        deadlineForReplyTime instanceof HTMLInputElement)
    ) {
      return;
    }

    const updateAfterStartsAtRadioButtonState = () => {
      if (
        (deadlineForReplyDate && deadlineForReplyDate.value) ||
        (deadlineForReplyTime && deadlineForReplyTime.value)
      ) {
        afterStartsAtRadioButton.disabled = true;
        afterStartsAtRadioButtonLabel.classList.add("disabled-label");
      } else {
        afterStartsAtRadioButton.disabled = false;
        afterStartsAtRadioButtonLabel.classList.remove("disabled-label");
      }
    };

    updateAfterStartsAtRadioButtonState();

    deadlineForReplyDate.addEventListener(
      "input",
      updateAfterStartsAtRadioButtonState,
    );
    deadlineForReplyTime.addEventListener(
      "input",
      updateAfterStartsAtRadioButtonState,
    );
  }

  // 選択された編集オプションに応じてフォームの送信先を変更する
  bulkEventsEditButton.addEventListener("click", (event) => {
    event.preventDefault();

    const editOptionElement = div.querySelector(
      'input[name="event[edit_option]"]:checked',
    );

    if (!(editOptionElement && editOptionElement instanceof HTMLInputElement)) {
      return;
    }

    const form = document.getElementById("bill-form");

    if (!(form && form instanceof HTMLFormElement)) {
      return;
    }

    if (editOptionElement.value === "only_event") {
      form.action = div.dataset.onlyPath;
    } else if (editOptionElement.value === "after_starts_at") {
      form.action = div.dataset.afterPath;
    }

    form.submit();
  });

  // キャンセルボタンを押したら「この予定だけ」が選択されるようにリセットする
  cancelButton.addEventListener("click", () => {
    const onlyEventRadioButton = div.querySelector(
      'input[name="event[edit_option]"][value="only_event"]',
    );

    if (
      !(onlyEventRadioButton &&
        onlyEventRadioButton instanceof HTMLInputElement)
    ) {
      return;
    }

    onlyEventRadioButton.checked = true;
  });
});
